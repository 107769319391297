import {useMemo, useState} from 'react';
import {Button} from 'src/components/ui/button';
import {EditableTextForm} from '../components/editable-text-input';
import {
  type UntereinheitDeleteMutation,
  type UntereinheitUpdateMutation,
  useUntereinheitenQuery,
  useUntereinheitCreateMutation,
  useUntereinheitDeleteMutation,
  useUntereinheitUpdateMutation,
} from './organisationseinheit.generated';

type GruppenErrorType =
  | NonNullable<
      UntereinheitUpdateMutation['untereinheitUpdate']['errors']
    >[number]['__typename']
  | NonNullable<
      UntereinheitDeleteMutation['untereinheitDelete']['errors']
    >[number]['__typename'];

export const OrganisationseinheitenVerwalten = () => {
  const context = useMemo(() => ({additionalTypenames: ['Untereinheit']}), []);
  const [{data}, refetch] = useUntereinheitenQuery({
    requestPolicy: 'network-only',
    context: context,
  });
  const untereinheiten =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.untereinheiten
      : [];

  const [, createUntereinheit] = useUntereinheitCreateMutation();
  const [, updateUntereinheit] = useUntereinheitUpdateMutation();
  const [, deleteUntereinheit] = useUntereinheitDeleteMutation();
  const [errors, setError] = useState<Record<string, GruppenErrorType>>({});

  const errorMap: Record<GruppenErrorType, string> = {
    UntereinheitNotFoundError:
      'Die Organisationseinheit existiert nicht und konnte deshalb nicht gelöscht werden.',
    UntereinheitAssignedToUser:
      'Die Organisationseinheit ist Nutzenden zugeordnet und kann deswegen nicht gelöscht werden.',
    UntereinheitIsGemeindeError:
      'Die Organisationseinheit ist eine Gemeinde und kann nicht bearbeitet oder gelöscht werden.',
    InvalidEmailError: 'Die EMailadresse ist ungültig.',
    NotAllowedError: 'Die Untereinheit konnnte nicht gelöscht werden.',
  };

  const maybeSetError = (
    id: string,
    maybeError?: {__typename: GruppenErrorType},
  ) => {
    if (maybeError) {
      setError((prev) => {
        prev[id] = maybeError.__typename;
        return prev;
      });
    }
  };
  return (
    <div className='flex flex-col'>
      {untereinheiten.map((u) => {
        const {isGemeinde, ...untereinheit} = u;
        return (
          <EditableTextForm
            key={untereinheit.id}
            onUpdate={async (nextOrganisationseinheit) => {
              const result = await updateUntereinheit({
                input: {
                  untereinheitId: untereinheit.id,
                  name: nextOrganisationseinheit.name,
                  eMailAdresse: nextOrganisationseinheit.eMailAdresse,
                },
              });
              const maybeError = result.data?.untereinheitUpdate.errors?.[0];
              maybeSetError(untereinheit.id, maybeError);
            }}
            onDelete={async () => {
              const result = await deleteUntereinheit({
                input: {untereinheitId: untereinheit.id},
              });
              const maybeError = result.data?.untereinheitDelete.errors?.[0];
              maybeSetError(untereinheit.id, maybeError);
              refetch();
            }}
            editable={!isGemeinde}
            error={errors[untereinheit.id] && errorMap[errors[untereinheit.id]]}
            object={untereinheit}
          />
        );
      })}

      <Button
        className='ml-auto mt-3 self-center'
        onClick={async () => {
          await createUntereinheit({
            input: {name: 'Neue Organisationseinheit'},
          });
        }}
      >
        + Neue Organisationseinheit hinzufügen
      </Button>
    </div>
  );
};
