import {useFormBuilder} from '@atmina/formbuilder';
import {useCallback, useState} from 'react';
import {type RouteObject} from 'react-router';
import {InputFormField} from 'src/components/form/fields/input-field';
import {Form} from 'src/components/form/form';
import {Button} from 'src/components/ui/button';
import {useAuth} from 'src/lib/context/auth/auth';
import {
  encryptPersonalKey,
  exportPublicKey,
  generateKeyPair,
  getServerLoginPassword,
  reencryptOrganisationKeyForNewUser,
} from 'src/lib/crypto/crypto';
import documentCheckIllustration from '../../../assets/document-check-illustration.svg';
import {useResetPasswordMutation} from './reset-password.generated';

type ResetPasswordForm = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const ResetPassword = () => {
  const builder = useFormBuilder<ResetPasswordForm>();
  const {user, personalPrivateKey} = useAuth();

  const [success, setSuccess] = useState<boolean | null>(null);

  const [, mutateResetPassword] = useResetPasswordMutation();

  const submit = useCallback(
    async (form: ResetPasswordForm) => {
      setSuccess(null);

      if (
        user?.__typename === 'BackofficeUser' &&
        user.organisationPrivateKey &&
        personalPrivateKey
      ) {
        const personalKeyPair = await generateKeyPair();

        const encryptedPersonalKey = await encryptPersonalKey(
          form.newPassword,
          user.eMail,
          personalKeyPair.privateKey,
        );

        const newOrganizationPrivateKey =
          await reencryptOrganisationKeyForNewUser(
            personalPrivateKey,
            user.organisationPrivateKey,
            personalKeyPair.publicKey,
          );

        const result = await mutateResetPassword({
          input: {
            currentPassword: await getServerLoginPassword(
              form.currentPassword,
              user.eMail,
            ),
            newPassword: await getServerLoginPassword(
              form.newPassword,
              user.eMail,
            ),
            personalPublicKey: await exportPublicKey(personalKeyPair.publicKey),
            personalPrivateKey: encryptedPersonalKey.encrypted,
            personalPrivateKeySalt: encryptedPersonalKey.salt,
            organizationPrivateKey: newOrganizationPrivateKey,
          },
        });

        setSuccess(!!result.data?.resetPassword.boolean);
      }
    },
    [mutateResetPassword, personalPrivateKey, user],
  );

  return (
    <div className='container flex h-full max-w-xl flex-col gap-4 py-8'>
      {success === true ? (
        <>
          <img
            src={documentCheckIllustration}
            className='mx-auto max-w-[150px]'
          />
          <h1 className='text-3xl font-bold text-gray-700'>
            Passwort zurückgesetzt
          </h1>
          <p>Ihr Passwort wurde erfolgreich zurückgesetzt.</p>
        </>
      ) : (
        <>
          <h1 className='text-2xl font-bold'>Passwort zurücksetzen</h1>
          <p>Bitte geben Sie ein neues Passwort ein.</p>
          <Form builder={builder} onSubmit={submit}>
            <InputFormField
              type='password'
              label='Altes Passwort'
              on={builder.fields.currentPassword}
              rules={{
                required: true,
              }}
            />

            <InputFormField
              type='password'
              label='Neues Passwort'
              on={builder.fields.newPassword}
              rules={{
                required: true,
              }}
            />

            <InputFormField
              type='password'
              label='Neues Passwort bestätigen'
              on={builder.fields.repeatNewPassword}
              rules={{
                required: true,
                validate: (value: string) => {
                  if (builder.getValues().newPassword !== value) {
                    return 'Passwörter müssen übereinstimmen.';
                  }
                },
              }}
            />
            <Button
              className='mt-4 w-full'
              disabled={user?.__typename !== 'BackofficeUser'}
            >
              Passwort zurücksetzen
            </Button>

            {success === false && (
              <p className='mt-4'>
                <span className='text-red-500'>
                  Beim Zurücksetzen Ihres Passwortes ist ein Fehler aufgetreten.
                </span>
              </p>
            )}
          </Form>
        </>
      )}
    </div>
  );
};

export const resetPasswordRoute: RouteObject = {
  path: 'reset-password',
  element: <ResetPassword />,
};
