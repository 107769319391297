import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import {
  type FC,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
  type ReactNode,
} from 'react';
import {type UseFormRegisterReturn} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';
import {getFieldError} from '../common.tsx';
import {FloatingLabelOutline} from '../floating-label-outline.tsx';

export type InputFieldProps = {
  label?: string;
  error?: string | true;
  minRows?: number;
  inputClass?: string;
  multiLine?: boolean;
  fieldsetClass?: string;

  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

export type InputFormFieldProps = {
  on: FormBuilder<string>;
  rules?: RegisterOptions<string>;
} & Omit<InputFieldProps, Exclude<keyof UseFormRegisterReturn, 'disabled'>>;

export const InputField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputFieldProps
>(
  (
    {
      error,
      className,
      inputClass: customInputClass,
      multiLine,
      minRows,
      startAdornment,
      endAdornment,
      fieldsetClass,
      ...props
    }: InputFieldProps,
    ref,
  ) => {
    // Space as placeholder allows use of placeholder-shown pseudo-selector; with an empty string or undefined it doesn't work.
    const placeholder = props.placeholder || ' ';
    const inputClass =
      'peer relative z-10 w-full bg-transparent px-3 py-2 outline-none placeholder:opacity-0 placeholder:transition-opacity group-focus-within:placeholder:opacity-100';
    return (
      <FloatingLabelOutline
        error={error}
        label={props.label}
        className={className}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        fieldsetClass={fieldsetClass}
      >
        {(id) =>
          multiLine ? (
            <textarea
              {...props}
              id={id}
              rows={10}
              className={twMerge(
                inputClass,
                customInputClass,
                startAdornment && 'pl-0',
                endAdornment && 'pr-0',
              )}
              placeholder={placeholder}
              ref={ref as ForwardedRef<HTMLTextAreaElement>}
              readOnly={
                typeof props.value === 'string' &&
                !(props.onChange || props.onInput)
              }
            />
          ) : (
            <input
              {...props}
              id={id}
              className={twMerge(
                inputClass,
                customInputClass,
                startAdornment && 'pl-0',
                endAdornment && 'pr-0',
              )}
              placeholder={placeholder}
              ref={ref as ForwardedRef<HTMLInputElement>}
              readOnly={
                typeof props.value === 'string' &&
                !(props.onChange || props.onInput)
              }
            />
          )
        }
      </FloatingLabelOutline>
    );
  },
);
InputField.displayName = 'InputField';

export const InputFormField: FC<InputFormFieldProps> = ({
  on,
  rules,
  ...props
}) => <InputField {...on(rules)} {...props} error={getFieldError(on)} />;
