import {type FC, useState} from 'react';
import {Button} from 'src/components/ui/button/button';
import {EditableTextForm} from '../components/editable-text-input';
import {
  type DeleteAbteilungUpdateErrorFragment,
  type UpdateAbteilungUpdateErrorFragment,
} from '../nutzende/nutzende.generated';
import {type GruppenVerwaltenProps} from '../nutzende/organisationseinheit-verwalten';
import {
  useAddAbteilungMutation,
  useDeleteAbteilungMutation,
  useUpdateAbteilungMutation,
} from './abteilungen.generated';

type GruppenErrorType =
  | UpdateAbteilungUpdateErrorFragment['__typename']
  | DeleteAbteilungUpdateErrorFragment['__typename'];

export const AbteilungenVerwalten: FC<GruppenVerwaltenProps> = ({
  abteilungen,
  onSaveDeleteOrUpdate,
}) => {
  const [, addAbteilungMutation] = useAddAbteilungMutation();
  const [, updateAbteilungMutation] = useUpdateAbteilungMutation();
  const [, deleteAbteilungMutation] = useDeleteAbteilungMutation();
  const [errors, setError] = useState<Record<string, GruppenErrorType>>({});

  const errorMap: Record<GruppenErrorType, string> = {
    GroupAssignedToSchritt:
      'Die Gruppe ist einem Prozessschritt zugeordnet und kann deshalb nicht gelöscht werden.',
    GroupNotFound:
      'Die Gruppe existiert nicht und konnte deshalb nicht gelöscht werden.',
    GroupNameInvalid: 'Abteilungsname ungültig.',
    InvalidEmailError: 'EMailadresse ungültig.',
  };

  const maybeSetError = (
    id: string,
    maybeError?: {__typename: GruppenErrorType},
  ) => {
    if (maybeError) {
      setError((prev) => {
        prev[id] = maybeError.__typename;
        return prev;
      });
    }
  };

  return (
    <>
      {abteilungen.map((abteilung) => (
        <EditableTextForm
          onUpdate={async (nextAbteilung) => {
            const result = await updateAbteilungMutation({
              input: {
                id: abteilung.id,
                name: nextAbteilung.name,
                eMailAdresse: nextAbteilung.eMailAdresse,
              },
            });
            const maybeError = result.data?.updateAbteilung.errors?.[0];
            maybeSetError(abteilung.id, maybeError);
            onSaveDeleteOrUpdate();
          }}
          key={abteilung.id}
          onDelete={async () => {
            const result = await deleteAbteilungMutation({
              input: {id: abteilung.id},
            });
            const maybeError = result.data?.deleteAbteilung.errors?.[0];
            maybeSetError(abteilung.id, maybeError);
            onSaveDeleteOrUpdate();
          }}
          error={errors[abteilung.id] && errorMap[errors[abteilung.id]]}
          object={abteilung}
        />
      ))}
      <div className='flex flex-col'>
        <Button
          className='ml-auto mt-3 self-center'
          onClick={() => {
            addAbteilungMutation({
              input: {
                name: 'Neue Abteilung',
              },
            });
            onSaveDeleteOrUpdate();
          }}
        >
          + Neue Abteilung hinzufügen
        </Button>
      </div>
    </>
  );
};
