import * as Types from '../../../__generated__/graphql';

import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddAbteilungInput = {
  name: string;
};

export type DeleteAbteilungInput = {
  id: string;
};

export type UpdateAbteilungInput = {
  eMailAdresse?: string | null;
  id: string;
  name: string;
};

export type UpdateAbteilungMutationVariables = Types.Exact<{
  input: UpdateAbteilungInput;
}>;

export type UpdateAbteilungMutation = {
  __typename?: 'Mutation';
  updateAbteilung: {
    __typename?: 'UpdateAbteilungPayload';
    mitarbeiterGruppe?: {
      __typename: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
    errors?: Array<
      {__typename: 'GroupNameInvalid'} | {__typename: 'InvalidEmailError'}
    > | null;
  };
};

export type DeleteAbteilungMutationVariables = Types.Exact<{
  input: DeleteAbteilungInput;
}>;

export type DeleteAbteilungMutation = {
  __typename?: 'Mutation';
  deleteAbteilung: {
    __typename: 'DeleteAbteilungPayload';
    mitarbeiterGruppe?: {
      __typename: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
    errors?: Array<
      {__typename: 'GroupAssignedToSchritt'} | {__typename: 'GroupNotFound'}
    > | null;
  };
};

export type AddAbteilungMutationVariables = Types.Exact<{
  input: AddAbteilungInput;
}>;

export type AddAbteilungMutation = {
  __typename?: 'Mutation';
  addAbteilung: {
    __typename?: 'AddAbteilungPayload';
    mitarbeiterGruppe?: {
      __typename: 'MitarbeiterGruppe';
      id: string;
      name: string;
    } | null;
  };
};

export const UpdateAbteilungDocument = gql`
  mutation UpdateAbteilung($input: UpdateAbteilungInput!) {
    updateAbteilung(input: $input) {
      mitarbeiterGruppe {
        __typename
        id
        name
      }
      errors {
        __typename
      }
    }
  }
`;

export function useUpdateAbteilungMutation() {
  return Urql.useMutation<
    UpdateAbteilungMutation,
    UpdateAbteilungMutationVariables
  >(UpdateAbteilungDocument);
}
export const DeleteAbteilungDocument = gql`
  mutation DeleteAbteilung($input: DeleteAbteilungInput!) {
    deleteAbteilung(input: $input) {
      __typename
      mitarbeiterGruppe {
        __typename
        id
        name
      }
      errors {
        __typename
      }
    }
  }
`;

export function useDeleteAbteilungMutation() {
  return Urql.useMutation<
    DeleteAbteilungMutation,
    DeleteAbteilungMutationVariables
  >(DeleteAbteilungDocument);
}
export const AddAbteilungDocument = gql`
  mutation AddAbteilung($input: AddAbteilungInput!) {
    addAbteilung(input: $input) {
      mitarbeiterGruppe {
        __typename
        id
        name
      }
    }
  }
`;

export function useAddAbteilungMutation() {
  return Urql.useMutation<AddAbteilungMutation, AddAbteilungMutationVariables>(
    AddAbteilungDocument,
  );
}
