import {type FC, type PropsWithChildren} from 'react';
import {useLocation} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {useKommune} from '../../../../../lib/context/kommune/kommune.tsx';
import {Link} from '../../../../link/link.tsx';
import {NavMenu} from '../../../../user-menu/menu-items.tsx';

export type NavigationProps = {
  ausfuehrungenNeuCount?: number;
  ausfuehrungenInBearbeitungCount?: number;
  ausfuehrungenAbgeschlossenCount?: number;
};

export const DesktopNavigation: FC<NavigationProps> = ({
  ausfuehrungenNeuCount,
  ausfuehrungenInBearbeitungCount,
  ausfuehrungenAbgeschlossenCount,
}) => {
  const {logoUrl} = useKommune();

  return (
    <div className='hidden h-20 bg-white shadow xl:block print:hidden'>
      <nav className='container flex size-full items-start justify-between gap-8 font-medium text-gray-600'>
        {logoUrl && (
          <img
            src={logoUrl}
            alt='Logo'
            className='max-h-full w-32 flex-1 self-center object-contain object-left py-4'
          />
        )}
        <div className='flex size-full gap-4'>
          <NavItemWithCount to='/antraege/neu' count={ausfuehrungenNeuCount}>
            Neue Anträge
          </NavItemWithCount>
          <NavItemWithCount
            to='/antraege/in-bearbeitung'
            count={ausfuehrungenInBearbeitungCount}
          >
            Anträge in Bearbeitung
          </NavItemWithCount>
          <NavItemWithCount
            to='/antraege/abgeschlossen'
            count={ausfuehrungenAbgeschlossenCount}
          >
            Abgeschlossen
          </NavItemWithCount>
          <NavMenu />
        </div>
      </nav>
    </div>
  );
};

const NavItemWithCount: FC<PropsWithChildren<{to: string; count?: number}>> = ({
  to,
  count,
  children,
}) => {
  const isActive = useLocation().pathname === to;

  return (
    <Link to={to} className='flex h-full flex-col justify-between self-center'>
      <div />
      <div>
        {children}
        {!!count && (
          <div
            className={twMerge(
              'ml-2 inline-flex size-6 items-center justify-center rounded-full text-xs',
              isActive
                ? 'bg-primary-light text-primary'
                : 'bg-gray-100 text-gray-400',
            )}
          >
            {count}
          </div>
        )}
      </div>
      <div
        className={twMerge(isActive && 'h-1 w-full rounded-xl bg-primary')}
      />
    </Link>
  );
};
