import {useFormBuilder} from '@atmina/formbuilder';
import {useCallback, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {
  type RouteObject,
  Link,
  useSearchParams,
  useParams,
} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {RegisterBackofficeUserResult} from 'src/__generated__/graphql.ts';
import {
  encryptPersonalKey,
  exportPublicKey,
  generateKeyPair,
  getServerLoginPassword,
} from 'src/lib/crypto/crypto.tsx';
import documentCheckIllustration from '../../../assets/document-check-illustration.svg';
import {EmailFormField} from '../../../components/form/fields/email-field/email-field.tsx';
import {InputFormField} from '../../../components/form/fields/input-field';
import {Form} from '../../../components/form/form.tsx';
import {Button, buttonVariants} from '../../../components/ui/button';
import {useAcceptInvitationMutation} from './accept-invitation.generated.ts';

type AcceptInvitationFormData = {
  email: string;
  vorname: string;
  nachname: string;
  password: string;
  repeatPassword: string;
};

export const AcceptInvitation = () => {
  const {code} = useParams<{code: string}>();
  const [params] = useSearchParams();

  const builder = useFormBuilder<AcceptInvitationFormData>({
    defaultValues: {
      email: params.get('email') || '',
      vorname: '',
      nachname: '',
      password: '',
      repeatPassword: '',
    },
  });

  const [result, setResult] = useState<RegisterBackofficeUserResult | null>(
    null,
  );

  const [, acceptInvitation] = useAcceptInvitationMutation();

  const submit = useCallback(
    async (data: AcceptInvitationFormData) => {
      if (!code) return;
      setResult(null);

      const password = await getServerLoginPassword(data.password, data.email);

      const personalKeyPair = await generateKeyPair();

      const encryptedPersonalKey = await encryptPersonalKey(
        data.password,
        data.email,
        personalKeyPair.privateKey,
      );

      const result = await acceptInvitation({
        input: {
          invitationCode: code,
          vorname: data.vorname,
          nachname: data.nachname,
          derivedPassword: password,
          publicKey: await exportPublicKey(personalKeyPair.publicKey),
          encryptedPrivateKey: encryptedPersonalKey.encrypted,
          salt: encryptedPersonalKey.salt,
        },
      });

      setResult(
        result.data?.registerBackofficeUser.result ??
          RegisterBackofficeUserResult.InvalidCode,
      );
    },
    [acceptInvitation, code],
  );

  return (
    <>
      <Helmet>
        <title>Registrierung</title>
      </Helmet>
      <div className='m-auto flex w-full max-w-md flex-col gap-4'>
        {result === RegisterBackofficeUserResult.Success ? (
          <Success />
        ) : (
          <>
            <h1 className='text-3xl font-bold text-gray-700'>Registrieren</h1>
            <p>
              Sie wurden eingeladen, sich zu registrieren. Bitte geben Sie
              folgende Daten an.
            </p>

            <Form builder={builder} onSubmit={submit}>
              <div className='flex flex-col gap-2'>
                <EmailFormField
                  disabled
                  label='E-Mail'
                  on={builder.fields.email}
                  autoComplete='email'
                  rules={{
                    required: true,
                  }}
                  className='w-full'
                />

                <InputFormField
                  label='Vorname'
                  on={builder.fields.vorname}
                  rules={{required: true}}
                  className='asdf'
                />
                <InputFormField
                  label='Nachname'
                  on={builder.fields.nachname}
                  rules={{required: true}}
                />

                <InputFormField
                  label='Passwort'
                  on={builder.fields.password}
                  type='password'
                  rules={{
                    required: true,
                  }}
                  className='w-full'
                />

                <InputFormField
                  label='Passwort bestätigen'
                  on={builder.fields.repeatPassword}
                  type='password'
                  rules={{
                    required: true,
                    validate: (value: string) => {
                      if (builder.getValues().password !== value) {
                        return 'Passwörter müssen übereinstimmen.';
                      }
                    },
                  }}
                  className='w-full'
                />
              </div>

              <Button variant='outlined' className='mt-4 w-full'>
                Registrieren
              </Button>

              {result === RegisterBackofficeUserResult.CodeExpired && (
                <p className='mt-4'>
                  Der Einladungs-Link ist abgelaufen. Bitte wenden Sie sich an
                  einen Administrator.
                </p>
              )}

              {result === RegisterBackofficeUserResult.InvalidCode && (
                <p className='mt-4'>
                  <span className='text-red-500'>
                    Beim der Registrierung ist ein Fehler aufgetreten.
                  </span>
                </p>
              )}
            </Form>
          </>
        )}
      </div>
    </>
  );
};

const Success = () => (
  <>
    <img src={documentCheckIllustration} className='mx-auto max-w-[150px]' />
    <h1 className='text-3xl font-bold text-gray-700'>
      Registrierung abgeschlossen
    </h1>
    <p>
      Ihre Registrierung war erfolgreich. Nach Bestätigung einer
      administrierenden Person können Sie sich mit Ihrer E-Mail Adresse und
      Ihrem Passwort anmelden.
    </p>
    <Link
      to='/auth/login'
      className={twMerge(
        buttonVariants({variant: 'outlined'}),
        'mt-4 w-full text-center',
      )}
    >
      Zum Login
    </Link>
  </>
);

export const acceptInvitationRoute: RouteObject = {
  path: 'accept-invitation/:code',
  element: <AcceptInvitation />,
};
