import {type FormBuilder} from '@atmina/formbuilder';
import {CheckboxField} from '../checkbox-field';

export type CheckboxListFormFieldProps = {
  label: string;
  items: {id: string; name: string}[];
  on: FormBuilder<string[]>;
};

// https://www.figma.com/design/v4Z0EWaF24LuqZ61g5bbwL/Kommunal-App?node-id=4471-12035&t=m3s6AirJ4k6sxztq-0

export const CheckboxCardListFormField = ({
  on,
  items,
  label,
}: CheckboxListFormFieldProps) => {
  const {
    field: {value = [], onChange},
  } = on.$useController();

  return (
    <div>
      <div className='flex items-center'>
        <div className='mr-4 whitespace-nowrap font-bold'>{label}</div>
        <div className='h-px w-full border-0 bg-neutral-300' />
      </div>
      <div className='flex flex-col gap-1'>
        {items.map(({id, name}) => (
          <CheckboxField
            key={id}
            className='cursor-pointer rounded border p-2'
            label={name}
            onChange={({currentTarget}) => {
              if (currentTarget.checked && !value.includes(id)) {
                onChange([...value, id]);
              } else {
                onChange(value.filter((other) => other != id));
              }
            }}
            value={value.includes(id)}
          />
        ))}
      </div>
    </div>
  );
};
