import {useFormBuilder} from '@atmina/formbuilder';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import {useState, type FC} from 'react';
import {MdAccountCircle} from 'react-icons/md';
import SVG from 'react-inlinesvg';
import {CheckboxField} from 'src/components/form/fields/checkbox-field/checkbox-field.tsx';
import {Form} from 'src/components/form/form.tsx';
import {Modal} from 'src/components/modal/modal';
import {Button} from 'src/components/ui/button';
import EditSquare from '../../../../assets/edit-square.svg';
import {Card} from '../../components/card.tsx';
import {SectionHeading} from '../../components/section-heading';
import {UntereinheitPermissionFormField} from '../../components/untereinheit-form-field';
import {
  type VerwalteteUntereinheitFragment,
  useUntereinheitenQuery,
} from '../../organisationseinheiten/organisationseinheit.generated';
import {
  useBackofficeUserUpdateMutation,
  type MitarbeiterGruppeFragment,
  type BackofficeUserUpdateInput,
  type UntereinheitPermissionsFragment,
} from '../nutzende.generated';
export type NutzenderVerwaltenProps = {
  nutzender: Nutzender;
  gruppen: MitarbeiterGruppeFragment[];
  onUpdate: () => void;
  disabled?: boolean;
};

type Nutzender = {
  id: string;
  eMail: string;
  vorname: string;
  nachname: string;
  gruppen: MitarbeiterGruppeFragment[];
  untereinheitPermissions: UntereinheitPermissionsFragment[];
};

export const NutzendenBearbeiten: FC<NutzenderVerwaltenProps> = ({
  nutzender,
  gruppen,
  onUpdate,
  disabled,
}) => {
  const [{data}] = useUntereinheitenQuery({
    requestPolicy: 'network-only',
  });

  const untereinheiten =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.untereinheiten
      : [];

  const [, updateBackofficeUser] = useBackofficeUserUpdateMutation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild disabled={disabled}>
        <Button variant='text' type='button' disabled={disabled}>
          <SVG src={EditSquare} className='fill-primary' />
        </Button>
      </Dialog.Trigger>
      {isOpen && (
        <NutzendenBearbeitenDialog
          nutzender={nutzender}
          gruppen={gruppen}
          untereinheiten={untereinheiten}
          onSubmit={async (input) => {
            const res = await updateBackofficeUser({input});
            if (res.data?.backofficeUserUpdate?.backofficeUser) {
              onUpdate();
              setIsOpen(false);
            }
          }}
        />
      )}
    </Dialog.Root>
  );
};

const NutzendenBearbeitenDialog = ({
  nutzender,
  onSubmit,
  untereinheiten,
  gruppen,
}: {
  nutzender: Nutzender;
  onSubmit: (input: BackofficeUserUpdateInput) => void;
  untereinheiten: VerwalteteUntereinheitFragment[];
  gruppen: MitarbeiterGruppeFragment[];
}) => {
  const backofficeUserFormbuilder = useFormBuilder<BackofficeUserUpdateInput>({
    defaultValues: {
      backofficeUserId: nutzender.id,
      permissions: nutzender.untereinheitPermissions.map((permission) => {
        const {untereinheitId, canEditNews, canEditVeranstaltungen} =
          permission; // We need to extract unwanted fields otherwise they will be submited
        return {untereinheitId, canEditNews, canEditVeranstaltungen};
      }),
      assignedGroups: nutzender.gruppen.map(({id}) => id),
    },
  });

  const permissionsArray =
    backofficeUserFormbuilder.fields.permissions.$useFieldArray();
  const permissionsFormState = backofficeUserFormbuilder.watch();

  const assignedGroups =
    backofficeUserFormbuilder.fields.assignedGroups.$useWatch();

  return (
    <Modal title='Nutzende verwalten'>
      <Form builder={backofficeUserFormbuilder} onSubmit={onSubmit}>
        <div className='space-y-6'>
          <div className='my-3 flex flex-col rounded-lg border-[1px] border-neutral-300 bg-neutral-100 p-4 sm:flex-row'>
            <MdAccountCircle className='my-auto mr-7 size-10 text-primary' />
            <div className='flex-col pr-7'>
              <div className='text-sm'>Vorname</div>
              <div className='font-bold'>{nutzender.vorname}</div>
            </div>
            <div className='flex-col pr-7'>
              <div className='text-sm'>Nachname</div>
              <div className='font-bold'>{nutzender.nachname}</div>
            </div>
            <div className='flex-col pr-7'>
              <div className='text-sm'>E-Mail Adresse</div>
              <div className='font-bold'>{nutzender.eMail}</div>
            </div>
          </div>

          <div>
            <Tabs.Root className='space-y-4' defaultValue='GruppenTab'>
              <Tabs.List className='flex shrink-0 border-b border-gray-100'>
                <Tabs.Trigger
                  className='flex cursor-default select-none items-center justify-center gap-4  bg-white p-5 leading-none outline-none first:rounded-tl-md last:rounded-tr-md hover:text-primary data-[state=active]:border-b-4 data-[state=active]:border-primary data-[state=active]:bg-primary-light/20 data-[state=active]:text-primary '
                  value='GruppenTab'
                >
                  Gruppen
                </Tabs.Trigger>
                <Tabs.Trigger
                  className='flex cursor-default select-none items-center justify-center gap-4  bg-white p-5 leading-none outline-none first:rounded-tl-md last:rounded-tr-md hover:text-primary data-[state=active]:border-b-4 data-[state=active]:border-primary data-[state=active]:bg-primary-light/20 data-[state=active]:text-primary '
                  value='UntereinheitenTab'
                >
                  Untereinheiten
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content
                value='GruppenTab'
                className='flex flex-col space-y-2 '
              >
                <SectionHeading label='Folgenden Gruppen zugehörig:' />
                <div className='flex flex-col gap-2'>
                  {assignedGroups.map((g) => (
                    <Card key={g}>
                      <CheckboxField
                        label={
                          gruppen.find((gruppe) => gruppe.id === g)?.name ?? ''
                        }
                        onChange={() => {
                          const indexOfId = assignedGroups.indexOf(g);
                          assignedGroups.splice(indexOfId, 1);
                          return backofficeUserFormbuilder.fields.assignedGroups.$setValue(
                            assignedGroups,
                          );
                        }}
                        value
                      />
                    </Card>
                  ))}
                </div>
                {/* Todo Ausblenden wenn keine weiteren Gruppen */}
                <SectionHeading label='Weitere Gruppen: ' />
                <div className='flex flex-col gap-2'>
                  {gruppen
                    .filter((g) => !assignedGroups.includes(g.id))
                    .map((g) => (
                      <Card key={g.id}>
                        <CheckboxField
                          label={
                            gruppen.find((gruppe) => gruppe.id === g.id)
                              ?.name ?? ''
                          }
                          onChange={() =>
                            backofficeUserFormbuilder.fields.assignedGroups.$setValue(
                              [...assignedGroups, g.id],
                            )
                          }
                          value={false}
                        />
                      </Card>
                    ))}
                </div>
              </Tabs.Content>
              <Tabs.Content value='UntereinheitenTab'>
                <SectionHeading label='Folgenden Untereinheiten zugehörig:' />
                <div className='flex flex-col gap-2 pb-4 pt-2'>
                  {permissionsArray.fields.map((permission, index) => (
                    <Card key={permission.$key}>
                      <UntereinheitPermissionFormField
                        on={permission}
                        onChange={() => permissionsArray.remove(index)}
                        untereinheiten={untereinheiten}
                      />
                    </Card>
                  ))}
                </div>
                <SectionHeading label='Weitere Untereinheiten: ' />
                <div className='mt-2 flex flex-col gap-2'>
                  {untereinheiten
                    .filter((u) =>
                      permissionsFormState.permissions.every(
                        ({untereinheitId}) => untereinheitId !== u.id,
                      ),
                    )
                    .map((untereinheit) => (
                      <Card key={untereinheit.id}>
                        <CheckboxField
                          key={untereinheit.id}
                          label={untereinheit.name}
                          onChange={() =>
                            permissionsArray.append({
                              untereinheitId: untereinheit.id,
                              canEditNews: false,
                              canEditVeranstaltungen: false,
                            })
                          }
                          value={false}
                        />
                      </Card>
                    ))}
                </div>
              </Tabs.Content>
            </Tabs.Root>
          </div>

          <div className='flex justify-end'>
            <Button variant='outlined'>Übernehmen</Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
