import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import {type FC} from 'react';
import {Modal} from '../../../components/modal/modal.tsx';
import {Button} from '../../../components/ui/button/index.ts';
import {AbteilungenVerwalten} from '../abteilungen/abteilungen-verwalten.tsx';
import {OrganisationseinheitenVerwalten} from '../organisationseinheiten/organisationseinheiten-verwalten.tsx';
import {type MitarbeiterGruppeFragment} from './nutzende.generated.ts';

export type GruppenVerwaltenProps = {
  abteilungen: MitarbeiterGruppeFragment[];
  onSaveDeleteOrUpdate: () => void;
};

export const OrganisationseinheitVerwalten: FC<GruppenVerwaltenProps> = ({
  abteilungen,
  onSaveDeleteOrUpdate,
}) => (
  <Dialog.Root>
    <Dialog.Trigger asChild>
      <Button variant='outlined'>Organisationseinheit verwalten</Button>
    </Dialog.Trigger>
    <Modal title='Organisationseinheiten verwalten'>
      <Tabs.Root className='space-y-4' defaultValue='OrganisationseinheitenTab'>
        <Tabs.List className='flex shrink-0 border-b border-gray-100'>
          <Tabs.Trigger
            className='flex cursor-default select-none items-center justify-center gap-4  bg-white p-5 leading-none outline-none first:rounded-tl-md last:rounded-tr-md hover:text-primary data-[state=active]:border-b-4 data-[state=active]:border-primary data-[state=active]:bg-primary-light/20 data-[state=active]:text-primary '
            value='OrganisationseinheitenTab'
          >
            Organisationseinheiten
          </Tabs.Trigger>
          <Tabs.Trigger
            className='flex cursor-default select-none items-center justify-center gap-4  bg-white p-5 leading-none outline-none first:rounded-tl-md last:rounded-tr-md hover:text-primary data-[state=active]:border-b-4 data-[state=active]:border-primary data-[state=active]:bg-primary-light/20 data-[state=active]:text-primary '
            value='AbteilungenTab'
          >
            Abteilungen
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content
          value='OrganisationseinheitenTab'
          className='flex flex-col space-y-2 '
        >
          <div className='mt-2 flex flex-col gap-2'>
            <OrganisationseinheitenVerwalten />
          </div>
        </Tabs.Content>
        <Tabs.Content value='AbteilungenTab'>
          <div className='flex flex-col gap-2'>
            <AbteilungenVerwalten
              abteilungen={abteilungen}
              onSaveDeleteOrUpdate={onSaveDeleteOrUpdate}
            />
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </Modal>
  </Dialog.Root>
);
