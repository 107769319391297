import {useFormBuilder} from '@atmina/formbuilder';
import {ArrowLeftIcon} from '@radix-ui/react-icons';
import {useCallback, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {
  type RouteObject,
  Link,
  useSearchParams,
  useParams,
} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {PasswordConfirmationResult} from 'src/__generated__/graphql.ts';
import {
  encryptPersonalKey,
  exportPublicKey,
  generateKeyPair,
  getServerLoginPassword,
} from 'src/lib/crypto/crypto.tsx';
import documentCheckIllustration from '../../../../assets/document-check-illustration.svg';
import {EmailFormField} from '../../../../components/form/fields/email-field/email-field.tsx';
import {InputFormField} from '../../../../components/form/fields/input-field';
import {Form} from '../../../../components/form/form.tsx';
import {Button, buttonVariants} from '../../../../components/ui/button';
import {useForgotPasswordConfirmMutation} from '../reset-password.generated.ts';

type ConfirmPasswordResetForm = {
  email: string;
  password: string;
  repeatPassword: string;
};

export const ConfirmResetPassword = () => {
  const {code} = useParams<{code: string}>();
  const [params] = useSearchParams();

  const email = useMemo(() => {
    const email = params.get('email');
    return email;
  }, [params]);

  const builder = useFormBuilder<ConfirmPasswordResetForm>({
    defaultValues: {
      email: email ?? '',
    },
  });

  const [result, setResult] = useState<PasswordConfirmationResult | null>(null);

  const [, mutateForgotPasswordConfirm] = useForgotPasswordConfirmMutation();

  const confirmResetPassword = useCallback(
    async (form: ConfirmPasswordResetForm) => {
      if (!code) return;
      setResult(null);

      const password = await getServerLoginPassword(form.password, form.email);

      const personalKeyPair = await generateKeyPair();

      const encryptedPersonalKey = await encryptPersonalKey(
        form.password,
        form.email,
        personalKeyPair.privateKey,
      );

      const result = await mutateForgotPasswordConfirm({
        input: {
          password,
          passwordResetCode: code,
          personalPublicKey: await exportPublicKey(personalKeyPair.publicKey),
          personalPrivateKey: encryptedPersonalKey.encrypted,
          personalPrivateKeySalt: encryptedPersonalKey.salt,
        },
      });

      setResult(
        result.data?.forgotPasswordConfirm.result ??
          PasswordConfirmationResult.InvalidCode,
      );
    },
    [code, mutateForgotPasswordConfirm],
  );

  const submit = useCallback(
    async (form: ConfirmPasswordResetForm) => {
      await confirmResetPassword(form);
    },
    [confirmResetPassword],
  );

  return (
    <>
      <Helmet>
        <title>Passwort zurücksetzen</title>
      </Helmet>
      <div className='m-auto flex w-full max-w-md flex-col gap-4'>
        {result === PasswordConfirmationResult.Success ? (
          <>
            <img
              src={documentCheckIllustration}
              className='mx-auto max-w-[150px]'
            />
            <h1 className='text-3xl font-bold text-gray-700'>
              Passwort zurückgesetzt
            </h1>
            <p>
              Ihr Passwort wurde erfolgreich zurückgesetzt. Nach Bestätigung
              einer administrierenden Person können Sie sich mit Ihrem neuen
              Passwort anmelden.
            </p>
            <Link
              to='/auth/login'
              className={twMerge(
                buttonVariants({variant: 'outlined'}),
                'mt-4 w-full text-center',
              )}
            >
              Zum Login
            </Link>
          </>
        ) : (
          <>
            <Link
              to='/auth/login'
              className='flex items-center gap-2 text-primary'
            >
              <ArrowLeftIcon />
              <span>Zurück zum Login</span>
            </Link>
            <h1 className='text-3xl font-bold text-gray-700'>
              Passwort zurücksetzen
            </h1>
            <p>Bitte geben Sie ein neues Passwort ein.</p>

            <Form builder={builder} onSubmit={submit}>
              <EmailFormField
                disabled
                label='E-Mail'
                on={builder.fields.email}
                autoComplete='email'
                rules={{
                  required: true,
                }}
                className='w-full'
              />

              <InputFormField
                label='Passwort'
                on={builder.fields.password}
                type='password'
                rules={{
                  required: true,
                }}
                className='w-full'
              />

              <InputFormField
                label='Passwort bestätigen'
                on={builder.fields.repeatPassword}
                type='password'
                rules={{
                  required: true,
                  validate: (value: string) => {
                    if (builder.getValues().password !== value) {
                      return 'Passwörter müssen übereinstimmen.';
                    }
                  },
                }}
                className='w-full'
              />

              <Button variant='outlined' className='mt-4 w-full'>
                Passwort zurücksetzen
              </Button>

              {result === PasswordConfirmationResult.CodeExpired && (
                <p className='mt-4'>
                  Der Link zum Zurücksetzen Ihres Passwortes ist abgelaufen.
                  Bitte{' '}
                  <Link to='/auth/reset-password' className='text-primary'>
                    erzeugen Sie einen neuen Link
                  </Link>
                  .
                </p>
              )}

              {result === PasswordConfirmationResult.InvalidCode && (
                <p className='mt-4'>
                  <span className='text-red-500'>
                    Beim Zurücksetzen Ihres Passwortes ist ein Fehler
                    aufgetreten.
                  </span>
                </p>
              )}
            </Form>
          </>
        )}
      </div>
    </>
  );
};

export const confirmResetPasswordRoute: RouteObject = {
  path: 'reset-password/:code',
  element: <ConfirmResetPassword />,
};
