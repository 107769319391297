import {
  InputField,
  type InputFieldProps,
  InputFormField,
  type InputFormFieldProps,
} from '../input-field';

export type EmailFieldProps = Omit<InputFieldProps, 'type'>;
export type EmailFormFieldProps = Omit<InputFormFieldProps, 'type'>;

export const EmailField = (props: EmailFieldProps) => (
  <InputField {...props} autoComplete='email' type='email' />
);

export const EmailFormField = ({on, rules, ...props}: EmailFormFieldProps) => (
  <InputFormField
    on={on}
    rules={{
      validate: (email: string) => {
        if (email.trim() && !/^[\u{20}-\u{7F}]*@/gu.test(email)) {
          return 'Bitte geben Sie eine gültige E-Mail Adresse ohne Sonderzeichen vor dem @ ein.';
        }
      },
      ...rules,
    }}
    {...props}
    type='email'
  />
);
