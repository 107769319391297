import {type FC, type PropsWithChildren, type ReactNode} from 'react';
import {
  MdAccountCircle,
  MdArrowDropDown,
  MdDashboardCustomize,
  MdHomeWork,
  MdLogout,
  MdMenu,
  MdOutlineEditNote,
} from 'react-icons/md';
import {RiShieldKeyholeLine} from 'react-icons/ri';
import {Link} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {useAuth} from '../../lib/context/auth/auth.tsx';
import {KommuneAdminPolicy} from '../kommune-admin-policy.tsx';
import {DropdownMenu} from '../ui/dropdown-menu/dropdown-menu.tsx';

export const mainMenuItems: {
  label: string;
  href: string;
  icon: ReactNode;
  external?: boolean;
  kommuneAdminRoute?: boolean;
}[] = [
  {
    label: 'Nutzendenverwaltung',
    href: '/nutzendenverwaltung',
    icon: <MdAccountCircle />,
    kommuneAdminRoute: true,
  },
  {
    label: 'Inhaltspflege',
    href: '/cms',
    icon: <MdDashboardCustomize />,
    external: true,
    kommuneAdminRoute: false,
  },
  {
    label: 'Prozessverwaltung',
    href: '/prozessverwaltung',
    icon: <MdOutlineEditNote />,
    kommuneAdminRoute: true,
  },
];

export const NavMenu = () => {
  const {logout} = useAuth();

  return (
    <div className='ml-auto flex gap-4 justify-self-end'>
      <UserMenu />
      <VerticalDivider />
      <div className='relative flex h-full items-center'>
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <MdMenu className='size-8 hover:cursor-pointer' />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content>
              {mainMenuItems.map(
                ({label, href, external, icon, kommuneAdminRoute}) =>
                  kommuneAdminRoute ? (
                    <KommuneAdminPolicy key={label}>
                      <DropdownMenu.Item key={label}>
                        <MenuItem href={href} external={external}>
                          {icon}
                          {label}
                        </MenuItem>
                      </DropdownMenu.Item>
                    </KommuneAdminPolicy>
                  ) : (
                    <DropdownMenu.Item key={label}>
                      <MenuItem href={href} external={external}>
                        {icon}
                        {label}
                      </MenuItem>
                    </DropdownMenu.Item>
                  ),
              )}
              <DropdownMenu.Arrow />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu>
      </div>
      <VerticalDivider />
      <button
        type='button'
        className='flex items-center text-primary'
        onClick={() => logout()}
      >
        Abmelden
        <MdLogout className='ml-2 inline-block size-6' />
      </button>
    </div>
  );
};

export const UserMenu: FC<{mobile?: boolean; className?: string}> = ({
  className,
}) => {
  const {user} = useAuth();

  const vorname = user?.vorname;
  const nachname = user?.nachname;

  const organisationseinheiten =
    user && 'organisationseinheiten' in user ? user.organisationseinheiten : [];

  const organisationseinheitTextShort =
    organisationseinheiten.length === 1
      ? organisationseinheiten[0].meta.name
      : `${organisationseinheiten.length} Organisationseinheiten`;

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <div
          className={twMerge(
            'flex items-center gap-2 hover:cursor-pointer',
            className,
          )}
        >
          <MdAccountCircle className='my-auto size-10 text-primary' />
          <div className='flex flex-col'>
            {vorname && nachname && (
              <p className='font-bold'>{`${vorname.at(0)}. ${nachname}`}</p>
            )}
            <div className='text-xs'>{organisationseinheitTextShort}</div>
          </div>
          <MdArrowDropDown />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          {organisationseinheiten.length > 1 && (
            <div className='mb-2 flex items-start gap-2 border-b border-gray-100 px-5 pb-2 text-gray-400'>
              <MdHomeWork className='mt-1' />
              <div className='whitespace-pre-wrap'>
                {organisationseinheiten.map((o) => o.meta.name).join('\n')}
              </div>
            </div>
          )}
          <DropdownMenu.Item asChild>
            <Link
              to='/profile/reset-password'
              className='flex items-center gap-2'
            >
              <RiShieldKeyholeLine />
              Passwort zurücksetzen
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Arrow />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};

const VerticalDivider: FC = () => (
  <hr className='my-auto h-8 w-[1px] bg-gray-200' />
);

const MenuItem: FC<PropsWithChildren<{href: string; external?: boolean}>> = ({
  href,
  external,
  children,
}) => (
  <Link
    to={href}
    className='flex w-full items-center gap-2 text-gray-600'
    reloadDocument={external}
  >
    {children}
  </Link>
);
