import {useFormBuilder} from '@atmina/formbuilder';
import SVG from 'react-inlinesvg';
import {useAuth} from 'src/lib/context/auth/auth.tsx';
import {
  BackofficeNutzerArt,
  BackofficeNutzerStatus,
} from '../../../__generated__/graphql.ts';
import Search from '../../../assets/search.svg';
import {InputFormField} from '../../../components/form/fields/input-field';
import {SelectFormField} from '../../../components/form/fields/select-field';
import {Button} from '../../../components/ui/button';
import {NutzendenBearbeiten} from './actions/nutzenden-bearbeiten.tsx';
import {NutzendenLoeschen} from './actions/nutzenden-loeschen.tsx';
import {
  type UntereinheitPermissionsFragment,
  useNutzendeQuery,
} from './nutzende.generated.ts';
import {ShowNutzendenEinladenDialogButton} from './nutzenden-einladen.tsx';
import {OrganisationseinheitVerwalten} from './organisationseinheit-verwalten.tsx';
import {PendingConfirmation} from './pending-confirmation/pending-confirmation.tsx';

type NutzendeSearch = {
  search: string;
  gruppe: string;
};

type MitarbeiterGruppe = {
  id: string;
  name: string;
};

export const Nutzende = () => {
  const {user, refetch: refetchCurrentUser} = useAuth();
  const formBuilder = useFormBuilder<NutzendeSearch>();

  const search = formBuilder.fields.search.$useWatch();
  const gruppe = formBuilder.fields.gruppe.$useWatch();

  const [{data}, refetchQuery] = useNutzendeQuery({
    variables: {
      search: search,
      groupId: gruppe,
    },
  });

  const refetch = () => {
    refetchCurrentUser();
    refetchQuery({requestPolicy: 'network-only'});
  };

  const abteilungen =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.mitarbeiterGruppen
      : null;

  const nutzende =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.mitarbeiter?.nodes
      : null;

  if (!nutzende || !abteilungen) return null;

  return (
    <div className='container flex h-full flex-col gap-6 px-4 pt-20'>
      <div className='flex flex-wrap gap-4'>
        <div className='flex-1'>
          <h1 className='text-2xl font-bold'>Nutzendenverwaltung</h1>
        </div>
        <div className='flex flex-wrap content-center gap-4'>
          <ShowNutzendenEinladenDialogButton
            onInvite={() => {
              refetch();
            }}
          >
            + Neu einladen
          </ShowNutzendenEinladenDialogButton>
          {user?.art === BackofficeNutzerArt.Superadmin && (
            <OrganisationseinheitVerwalten
              abteilungen={abteilungen}
              onSaveDeleteOrUpdate={() => {
                refetch();
              }}
            />
          )}
        </div>
      </div>
      <PendingConfirmation />
      <div className='flex flex-wrap gap-4'>
        <h2 className='w-full text-lg font-bold'>Alle Nutzenden</h2>
        <p className='max-w-3xl'>
          In der Tabelle finden Sie eine Übersicht aller Nutzenden und können
          die jeweiligen Gruppen bearbeiten. Mit der Suche können Sie einzelne
          Nutzende suchen.
        </p>
        <InputFormField
          on={formBuilder.fields.search}
          label='Nach Namen oder E-Mail Adressen suchen...'
          endAdornment={
            <Button
              variant='solid'
              className='flex w-min items-center gap-2 rounded-l-none px-4'
            >
              <SVG src={Search} className='size-5 fill-white' />
              Suchen
            </Button>
          }
          className='w-[38rem]'
        />
        <SelectFormField
          on={formBuilder.fields.gruppe}
          label='Nach Gruppen filtern'
          items={[
            {key: 'alle', name: 'Alle Gruppen', value: undefined},
            ...abteilungen.map((g) => ({
              key: g.id,
              value: g.id,
              name: g.name,
            })),
          ]}
          className='w-60'
        />
      </div>
      <table>
        <thead className='text-left'>
          <tr>
            <th className='pb-2 pl-5 pr-12'>Art</th>
            <th className='pb-2'>Vorname</th>
            <th className='pb-2 pr-20'>Nachname</th>
            <th className='pb-2'>E-Mail Adresse</th>
            <th className='pb-2'>Zuordnungen</th>
            <th />
          </tr>
        </thead>
        <tbody className='border-t-2'>
          {nutzende.map((n) => (
            <tr key={n.id} className='m-4 even:bg-neutral-100'>
              <td className='py-4 pl-5'>
                <StatusColumn art={n.art} status={n.status} />
              </td>
              <td>{n.vorname}</td>
              <td>{n.nachname}</td>
              <td>{n.eMail}</td>
              <td>
                <div className='max-w-96 overflow-hidden text-ellipsis text-nowrap'>
                  <ZuordnungenColumn {...n} />
                </div>
              </td>
              <td>
                <div className='mr-4 flex justify-end gap-2'>
                  <NutzendenBearbeiten
                    nutzender={n}
                    gruppen={abteilungen}
                    disabled={!n.canBeUpdated}
                    onUpdate={refetch}
                  />
                  <NutzendenLoeschen
                    userId={n.id}
                    userName={
                      n.nachname ? `${n.vorname} ${n.nachname}` : n.eMail
                    }
                    disabled={!n.canBeDeleted}
                    onDelete={refetch}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        {nutzende.length === 0 && (
          <tfoot>
            <tr>
              <td colSpan={3}>Keine Nutzenden gefunden.</td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

const arten: Record<BackofficeNutzerArt, string> = {
  [BackofficeNutzerArt.Nutzer]: 'Nutzer',
  [BackofficeNutzerArt.Admin]: 'Administrator',
  [BackofficeNutzerArt.Superadmin]: 'Super-Administrator',
};

const statuses: Record<BackofficeNutzerStatus, string> = {
  [BackofficeNutzerStatus.Registered]: 'Registriert',
  [BackofficeNutzerStatus.Invited]: 'Eingeladen',
  [BackofficeNutzerStatus.InvitationExpired]: 'Einladung abgelaufen',
};

const StatusColumn = ({
  art,
  status,
}: {
  art: BackofficeNutzerArt;
  status?: BackofficeNutzerStatus;
}) => (
  <div>
    <div>{arten[art]}</div>
    {status && status != BackofficeNutzerStatus.Registered && (
      <div className='text-sm text-primary'>{statuses[status]}</div>
    )}
  </div>
);

const ZuordnungenColumn = ({
  untereinheitPermissions,
  gruppen,
}: {
  untereinheitPermissions: UntereinheitPermissionsFragment[];
  gruppen: MitarbeiterGruppe[];
}) => {
  const einheitenText = untereinheitPermissions
    .map((einheit) => einheit.untereinheit.name)
    .join(', ');
  const gruppenText = gruppen.map((g) => g.name).join(', ');
  return (
    <div>
      <div className='truncate' title={einheitenText}>
        {einheitenText || 'Keine Organisationseinheiten'}
      </div>
      <div className='text-sm text-gray-900' title={gruppenText}>
        {gruppenText}
      </div>
    </div>
  );
};
