import * as Types from '../../__generated__/graphql';

import {
  SlotValue_EncryptedSlotValue_Fragment,
  SlotValue_PlainSlotValue_Fragment,
} from '../../components/ui/slot-value/slot-value.generated';
import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import {SlotValueFragmentDoc} from '../../components/ui/slot-value/slot-value.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type AusfuehrungFragment = {
  __typename?: 'Ausfuehrung';
  id: string;
  sharedKeyEncryptedForKommune: string;
  createdAt: Date;
  zugewiesenePerson?: {
    __typename?: 'BackofficeUser';
    vorname: string;
    nachname: string;
  } | null;
  prozessVersion: {
    __typename?: 'ProzessVersion';
    id: string;
    beschreibungstext: StringExpression;
    prozess: {__typename?: 'Prozess'; id: string; name: string};
  };
  currentSchritt:
    | {
        __typename?: 'BezahlenSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'EmailForwarderExportSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'EndeSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'FormularSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'GovManagerRestExportSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'ProgrammatischerSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'WarteAufBezahlungAbgeschlossenSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'WarteAufGovManagerSchritt';
        id: string;
        mitarbeiterGruppe?: {
          __typename?: 'MitarbeiterGruppe';
          id: string;
          name: string;
        } | null;
      };
  beschreibungstextSlotValues: Array<
    | ({
        __typename?: 'EncryptedSlotValue';
      } & SlotValue_EncryptedSlotValue_Fragment)
    | ({__typename?: 'PlainSlotValue'} & SlotValue_PlainSlotValue_Fragment)
  >;
};

export type AusfuehrungenQueryVariables = Types.Exact<{
  filter?: Types.Maybe<Types.AssignedAusfuehrungenFilter>;
}>;

export type AusfuehrungenQuery = {
  __typename?: 'Query';
  my:
    | {
        __typename: 'BackofficeUser';
        id: string;
        assignedAusfuehrungen: Array<
          {__typename?: 'Ausfuehrung'} & AusfuehrungFragment
        >;
      }
    | {__typename: 'Buerger'; id: string}
    | {__typename: 'SystemUser'; id: string};
};

export const AusfuehrungFragmentDoc = gql`
  fragment Ausfuehrung on Ausfuehrung {
    id
    sharedKeyEncryptedForKommune
    createdAt
    zugewiesenePerson {
      vorname
      nachname
    }
    prozessVersion {
      id
      beschreibungstext
      prozess {
        id
        name
      }
    }
    currentSchritt {
      id
      mitarbeiterGruppe {
        id
        name
      }
    }
    beschreibungstextSlotValues {
      ...SlotValue
    }
  }
  ${SlotValueFragmentDoc}
`;
export const AusfuehrungenDocument = gql`
  query Ausfuehrungen($filter: AssignedAusfuehrungenFilter) {
    my {
      id
      __typename
      ... on BackofficeUser {
        assignedAusfuehrungen(filter: $filter) {
          ...Ausfuehrung
        }
      }
    }
  }
  ${AusfuehrungFragmentDoc}
`;

export function useAusfuehrungenQuery(
  options?: Omit<Urql.UseQueryArgs<AusfuehrungenQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AusfuehrungenQuery, AusfuehrungenQueryVariables>({
    query: AusfuehrungenDocument,
    ...options,
  });
}
