import {type FC} from 'react';
import SVG from 'react-inlinesvg';

export const IconWithCallbackButton: FC<{
  onClick: () => void;
  iconSrc: string;
}> = ({onClick, iconSrc}) => (
  <button
    className='material-symbols-outlined flex gap-2 text-primary'
    onClick={() => {
      onClick();
    }}
  >
    <SVG src={iconSrc} className='fill-primary' />
  </button>
);
