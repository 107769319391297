import {Outlet, type RouteObject} from 'react-router-dom';
import {supportsWebCrypto} from '../../lib/crypto/crypto.tsx';
import {useAsyncMemo} from '../../lib/hooks/use-async-memo.ts';
import {acceptInvitationRoute} from './accept-invitation/accept-invitation.tsx';
import {SlidingKommuneDisplay} from './components/sliding-kommune-display.tsx';
import {loginRoute} from './login/login.tsx';
import {confirmResetPasswordRoute} from './reset-password/confirm/confirm.tsx';
import {resetPasswordRoute} from './reset-password/reset-password.tsx';

export const AuthPage = () => {
  const doesSupportWebCrypto = useAsyncMemo(() => supportsWebCrypto(), []);

  return (
    <div className='flex h-screen flex-col bg-white lg:flex-row'>
      <div className='flex-[4] gap-8 lg:flex lg:flex-col'>
        {doesSupportWebCrypto === false && (
          <div className='m-auto max-w-2xl text-lg font-medium'>
            Ihr Browser unterstützt nicht die nötigen Schnittstellen zur
            Ende-zu-Ende Verschlüsselung. Bitte verwenden Sie einen modernen
            Browser.
          </div>
        )}
        {doesSupportWebCrypto !== false && (
          <div className='flex flex-1 flex-col overflow-auto px-4 py-8 sm:px-6 lg:px-20 xl:px-24'>
            <Outlet />
          </div>
        )}
        <div className='flex justify-center gap-x-4 px-4 py-2 text-gray-400'>
          <a href='/impressum' rel='noreferrer noopener' target='_blank'>
            Impressum
          </a>
          <a href='/datenschutz' rel='noreferrer noopener' target='_blank'>
            Datenschutzerklärung
          </a>
        </div>
      </div>

      <div className='hidden flex-[6] lg:flex'>
        <SlidingKommuneDisplay />
      </div>
    </div>
  );
};

export const authRoute: RouteObject = {
  path: 'auth',
  element: <AuthPage />,
  children: [
    loginRoute,
    resetPasswordRoute,
    confirmResetPasswordRoute,
    acceptInvitationRoute,
  ],
};
