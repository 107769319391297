import {type FormBuilder} from '@atmina/formbuilder';
import {ExpressionInputFormField} from '../../../../../components/form/fields/expression-input-field/expression-input-field.tsx';
import type {SchrittDaten} from '../../lib/store/prozess-aenderungen.ts';

type FormData = Extract<
  SchrittDaten,
  {__typename: 'EmailForwarderExportSchritt'}
>;

export const EmailWeiterleitenSchritt = ({on}: {on: FormBuilder<FormData>}) => (
  <div>
    <ExpressionInputFormField
      label='E-Mail Adresse'
      kind='string'
      on={on.emailAddress}
    />
    <ExpressionInputFormField label='Betreff' kind='string' on={on.subject} />
    <ExpressionInputFormField label='Inhalt' kind='string' on={on.body} />
  </div>
);
