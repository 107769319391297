import * as Types from '../../../__generated__/graphql';

import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BackofficeUserUpdateInput = {
  assignedGroups: Array<string>;
  backofficeUserId: string;
  permissions: Array<PermissionInput>;
};

export type PermissionInput = {
  canEditNews: boolean;
  canEditVeranstaltungen: boolean;
  untereinheitId: string;
};

export type NutzendeQueryVariables = Types.Exact<{
  groupId?: Types.Maybe<string>;
  search?: Types.Maybe<string>;
}>;

export type NutzendeQuery = {
  __typename?: 'Query';
  my:
    | {
        __typename?: 'BackofficeUser';
        kommune: {
          __typename?: 'Kommune';
          mitarbeiter?: {
            __typename?: 'MitarbeiterConnection';
            nodes?: Array<{
              __typename?: 'BackofficeUser';
              publicKey?: string | null;
              pendingConfirmation?: Types.PendingConfirmationReason | null;
              art: Types.BackofficeNutzerArt;
              status: Types.BackofficeNutzerStatus;
              canBeDeleted: boolean;
              canBeUpdated: boolean;
              id: string;
              vorname: string;
              nachname: string;
              eMail: string;
              untereinheitPermissions: Array<
                {
                  __typename?: 'UntereinheitPermissions';
                } & UntereinheitPermissionsFragment
              >;
              gruppen: Array<{
                __typename?: 'MitarbeiterGruppe';
                id: string;
                name: string;
              }>;
            }> | null;
          } | null;
          mitarbeiterGruppen: Array<
            {__typename?: 'MitarbeiterGruppe'} & MitarbeiterGruppeFragment
          >;
          untereinheiten: Array<
            {__typename?: 'Untereinheit'} & UntereinheitFragment
          >;
        };
      }
    | {__typename?: 'Buerger'}
    | {__typename?: 'SystemUser'};
};

export type UntereinheitPermissionsFragment = {
  __typename?: 'UntereinheitPermissions';
  untereinheitId: string;
  backofficeUserId: string;
  canEditNews: boolean;
  canEditVeranstaltungen: boolean;
  untereinheit: {__typename?: 'Untereinheit'; name: string};
};

export type UntereinheitFragment = {
  __typename?: 'Untereinheit';
  id: string;
  name: string;
  eMailAdresse?: string | null;
};

export type AddGroupToMitarbeiterMutationVariables = Types.Exact<{
  groupId: string;
  mitarbeiterId: string;
}>;

export type AddGroupToMitarbeiterMutation = {
  __typename?: 'Mutation';
  addGroupToMitarbeiter: {
    __typename?: 'AddGroupToMitarbeiterPayload';
    mitarbeiter?: {
      __typename?: 'BackofficeUser';
      id: string;
      vorname: string;
      nachname: string;
    } | null;
  };
};

export type BackofficeUserUpdateMutationVariables = Types.Exact<{
  input: BackofficeUserUpdateInput;
}>;

export type BackofficeUserUpdateMutation = {
  __typename?: 'Mutation';
  backofficeUserUpdate: {
    __typename: 'BackofficeUserUpdatePayload';
    backofficeUser?: {__typename?: 'BackofficeUser'; id: string} | null;
    errors?: Array<{__typename?: 'NotAllowedError'; message: string}> | null;
  };
};

export type RemoveGroupFromMitarbeiterMutationVariables = Types.Exact<{
  groupId: string;
  mitarbeiterId: string;
}>;

export type RemoveGroupFromMitarbeiterMutation = {
  __typename?: 'Mutation';
  removeGroupFromMitarbeiter: {
    __typename?: 'RemoveGroupFromMitarbeiterPayload';
    mitarbeiter?: {
      __typename?: 'BackofficeUser';
      id: string;
      vorname: string;
      nachname: string;
    } | null;
  };
};

export type DeleteAbteilungUpdateError_GroupAssignedToSchritt_Fragment = {
  __typename: 'GroupAssignedToSchritt';
};

export type DeleteAbteilungUpdateError_GroupNotFound_Fragment = {
  __typename: 'GroupNotFound';
};

export type DeleteAbteilungUpdateErrorFragment =
  | DeleteAbteilungUpdateError_GroupAssignedToSchritt_Fragment
  | DeleteAbteilungUpdateError_GroupNotFound_Fragment;

export type UpdateAbteilungUpdateError_GroupNameInvalid_Fragment = {
  __typename: 'GroupNameInvalid';
};

export type UpdateAbteilungUpdateError_InvalidEmailError_Fragment = {
  __typename: 'InvalidEmailError';
};

export type UpdateAbteilungUpdateErrorFragment =
  | UpdateAbteilungUpdateError_GroupNameInvalid_Fragment
  | UpdateAbteilungUpdateError_InvalidEmailError_Fragment;

export type MitarbeiterGruppeFragment = {
  __typename?: 'MitarbeiterGruppe';
  id: string;
  name: string;
  eMailAdresse?: string | null;
};

export const UntereinheitPermissionsFragmentDoc = gql`
  fragment untereinheitPermissions on UntereinheitPermissions {
    untereinheitId
    backofficeUserId
    canEditNews
    canEditVeranstaltungen
    untereinheit {
      name
    }
  }
`;
export const UntereinheitFragmentDoc = gql`
  fragment Untereinheit on Untereinheit {
    id
    name
    eMailAdresse
  }
`;
export const DeleteAbteilungUpdateErrorFragmentDoc = gql`
  fragment DeleteAbteilungUpdateError on DeleteAbteilungError {
    __typename
  }
`;
export const UpdateAbteilungUpdateErrorFragmentDoc = gql`
  fragment UpdateAbteilungUpdateError on UpdateAbteilungError {
    __typename
  }
`;
export const MitarbeiterGruppeFragmentDoc = gql`
  fragment MitarbeiterGruppe on MitarbeiterGruppe {
    id
    name
    eMailAdresse
  }
`;
export const NutzendeDocument = gql`
  query Nutzende($groupId: UUID, $search: String) {
    my {
      ... on BackofficeUser {
        kommune {
          mitarbeiter(groupId: $groupId, search: $search) {
            nodes {
              id
              vorname
              nachname
              eMail
              gruppen {
                id
                name
              }
              ... on BackofficeUser {
                publicKey
                pendingConfirmation
                untereinheitPermissions {
                  ...untereinheitPermissions
                }
                art
                status
                canBeDeleted
                canBeUpdated
              }
            }
          }
          mitarbeiterGruppen {
            ...MitarbeiterGruppe
          }
          untereinheiten {
            ...Untereinheit
          }
        }
      }
    }
  }
  ${UntereinheitPermissionsFragmentDoc}
  ${MitarbeiterGruppeFragmentDoc}
  ${UntereinheitFragmentDoc}
`;

export function useNutzendeQuery(
  options?: Omit<Urql.UseQueryArgs<NutzendeQueryVariables>, 'query'>,
) {
  return Urql.useQuery<NutzendeQuery, NutzendeQueryVariables>({
    query: NutzendeDocument,
    ...options,
  });
}
export const AddGroupToMitarbeiterDocument = gql`
  mutation addGroupToMitarbeiter($groupId: UUID!, $mitarbeiterId: UUID!) {
    addGroupToMitarbeiter(
      input: {groupId: $groupId, mitarbeiterId: $mitarbeiterId}
    ) {
      mitarbeiter {
        id
        vorname
        nachname
      }
    }
  }
`;

export function useAddGroupToMitarbeiterMutation() {
  return Urql.useMutation<
    AddGroupToMitarbeiterMutation,
    AddGroupToMitarbeiterMutationVariables
  >(AddGroupToMitarbeiterDocument);
}
export const BackofficeUserUpdateDocument = gql`
  mutation backofficeUserUpdate($input: BackofficeUserUpdateInput!) {
    backofficeUserUpdate(input: $input) {
      __typename
      backofficeUser {
        id
      }
      errors {
        ... on Error {
          message
        }
      }
    }
  }
`;

export function useBackofficeUserUpdateMutation() {
  return Urql.useMutation<
    BackofficeUserUpdateMutation,
    BackofficeUserUpdateMutationVariables
  >(BackofficeUserUpdateDocument);
}
export const RemoveGroupFromMitarbeiterDocument = gql`
  mutation removeGroupFromMitarbeiter($groupId: UUID!, $mitarbeiterId: UUID!) {
    removeGroupFromMitarbeiter(
      input: {groupId: $groupId, mitarbeiterId: $mitarbeiterId}
    ) {
      mitarbeiter {
        id
        vorname
        nachname
      }
    }
  }
`;

export function useRemoveGroupFromMitarbeiterMutation() {
  return Urql.useMutation<
    RemoveGroupFromMitarbeiterMutation,
    RemoveGroupFromMitarbeiterMutationVariables
  >(RemoveGroupFromMitarbeiterDocument);
}
