import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import * as RadioGroup from '@radix-ui/react-radio-group';
import {useCallback} from 'react';
import {twMerge} from 'tailwind-merge';
import {
  type CollectionItem,
  findCollectionItemByKey,
  findCollectionItemByValue,
  getCollectionItemKey,
} from '../collections.ts';

export type RadioFieldProps<TItem> = {
  name?: string;
  items: (CollectionItem<TItem> & {disabled?: boolean})[];
  valueIdentifier?: keyof TItem;

  className?: string;
  label?: string;
  error?: string | true;

  value?: TItem;
  onChange: (value: TItem | null) => void;

  required?: boolean;
  disabled?: boolean;

  orientation?: 'horizontal' | 'vertical';
};

export type RadioFormFieldProps<TItem> = {
  on: FormBuilder<TItem>;
  rules?: RegisterOptions<TItem>;
} & Omit<RadioFieldProps<TItem>, 'value' | 'onChange'>;

export const RadioField = <TItem,>({
  name,
  items,
  value,
  disabled,
  onChange,
  className,
  orientation = 'vertical',
  required,
}: RadioFieldProps<TItem>) => {
  const currentItem = findCollectionItemByValue(items, value, name);

  const currentItemKey =
    currentItem?.key ?? getCollectionItemKey(currentItem?.value);

  const handleChange = useCallback(
    (newValue: string) => {
      const item = findCollectionItemByKey(items, newValue);

      if (item) onChange(item.value);
    },
    [items, onChange],
  );

  return (
    <RadioGroup.Root
      value={currentItemKey}
      disabled={disabled}
      className={twMerge(
        orientation === 'horizontal' && 'flex gap-6',
        className,
      )}
      onValueChange={handleChange}
      orientation={orientation}
      required={required}
    >
      {items.map(
        ({
          value: itemValue,
          key: itemKey = getCollectionItemKey(itemValue),
          name: itemName = itemKey,
          disabled = false,
        }) => (
          <label
            key={itemKey}
            className={twMerge(
              'flex cursor-pointer items-center gap-2 py-1',
              disabled && 'cursor-not-allowed text-gray-300',
            )}
          >
            <RadioGroup.Item
              value={itemKey}
              className={twMerge(
                'size-[18px] rounded-full border border-gray-300 bg-white outline-none focus:shadow-[0_0_0_2px] focus:shadow-primary',
              )}
              disabled={disabled}
            >
              <RadioGroup.Indicator
                className={twMerge(
                  'relative flex size-full items-center justify-center after:block after:size-3 after:rounded-full',
                  disabled ? 'after:bg-gray-700' : 'after:bg-primary',
                )}
              />
            </RadioGroup.Item>
            <div>{itemName}</div>
          </label>
        ),
      )}
    </RadioGroup.Root>
  );
};

export const RadioFormField = <TItem,>({
  on,
  rules,
  disabled,
  ...props
}: RadioFormFieldProps<TItem>) => {
  const {
    field: {value, onChange},
  } = on.$useController();

  return (
    <RadioField
      {...props}
      required={rules?.required?.toString() === 'true'}
      disabled={rules?.disabled || disabled}
      value={value}
      onChange={onChange}
    />
  );
};
