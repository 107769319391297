import {useEffect, useRef, useState} from 'react';
import SVG from 'react-inlinesvg';
import {twMerge} from 'tailwind-merge';
import {IconWithCallbackButton} from 'src/components/ui/button/icon-with-callback-button';
import Delete from '../../../assets/delete.svg';
import EditSquare from '../../../assets/edit-square.svg';
import Save from '../../../assets/save.svg';

export type EditableTextInputProps<T> = {
  onUpdate: (object: InputTypes<T>) => Promise<void>;
  onDelete: () => void;
  object: T;
  error?: string;
  editable?: boolean;
};
export type InputTypes<T> = Omit<T, '__typename' | 'id'>;

export const EditableTextForm = <
  T extends {__typename?: string | undefined; id?: string},
>({
  error,
  object,
  onUpdate,
  onDelete,
  editable = true,
}: EditableTextInputProps<T>) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const {__typename, id, ...rest} = object;

  const [editableValues, setEditableValues] = useState<InputTypes<T>>(rest);

  useEffect(() => {
    if (saveEnabled && inputRefs.current.length > 0) {
      inputRefs.current[0]?.focus();
    }
  }, [saveEnabled]);

  return (
    <>
      <div className='mt-2 flex items-center justify-between rounded-md border'>
        <div className='w-full p-2'>
          <div className='flex gap-2'>
            {Object.keys(editableValues).map((key, index) => {
              const value = editableValues[key as keyof InputTypes<T>];
              return (
                <>
                  {index != 0 && (
                    <div className='w-px self-stretch bg-slate-300' />
                  )}
                  <input
                    key={key}
                    ref={(el) => {
                      if (el) inputRefs.current[index] = el;
                    }}
                    disabled={!saveEnabled}
                    className='flex-1 rounded-md border-2 bg-gray-100 p-1 outline-gray-300 focus:outline-gray-500 disabled:border-transparent disabled:bg-transparent'
                    type='text'
                    id={key}
                    onChange={(e) =>
                      setEditableValues((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }))
                    }
                    defaultValue={value != null ? String(value) : ''}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div
          className={twMerge(
            'mx-1 flex flex-row gap-2',
            !editable ? 'invisible' : '',
          )}
        >
          <IconWithCallbackButton
            onClick={
              saveEnabled
                ? () => {
                    onUpdate(editableValues);
                    setSaveEnabled(!saveEnabled);
                  }
                : () => setSaveEnabled(!saveEnabled)
            }
            iconSrc={saveEnabled ? Save : EditSquare}
          />
          <button
            type='button'
            className='material-symbols-outlined flex gap-2 text-red-500'
            onClick={onDelete}
          >
            <SVG src={Delete} className='fill-red-500' />
          </button>
        </div>
      </div>
      {error && <div className='text-sm text-red-600'>{error}</div>}
    </>
  );
};
