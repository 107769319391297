import {type ComponentPropsWithoutRef, forwardRef, type ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';
import {tv, type VariantProps} from 'tailwind-variants';

export const buttonVariants = tv({
  base: 'relative rounded px-4 py-2 hover:shadow active:text-primary active:shadow-none',
  variants: {
    variant: {
      outlined: 'border border-primary bg-white text-primary',
      solid: 'bg-primary text-white',
      text: 'text-primary font-bold hover:shadow-none',
      'text-secondary': 'text-gray-900 hover:shadow-none active:text-gray-700',
      destructive: 'bg-red-500 font-semibold text-white',
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50',
    },
  },
  defaultVariants: {
    variant: 'outlined',
  },
});

type ButtonVariants = VariantProps<typeof buttonVariants>;

export type ButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonVariants & {submittingState?: boolean; endAdornment?: ReactNode};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      className,
      children,
      disabled,
      submittingState,
      endAdornment,
      ...rest
    },
    ref,
  ) => {
    const formContext = useFormContext();
    const submitting = submittingState ?? formContext?.formState.isSubmitting;

    const fullDisabled = submitting || disabled;

    return (
      <button
        {...rest}
        ref={ref}
        className={buttonVariants({variant, className, disabled: fullDisabled})}
        disabled={fullDisabled}
      >
        <div className='flex items-center justify-center'>
          {submitting && (
            <div
              className={twMerge(
                'absolute inset-0 backdrop-blur-[1px] transition-opacity duration-500',
                !submitting && 'opacity-0',
              )}
            >
              <div className='mx-auto size-6 animate-spin rounded-full border-2 border-white border-t-[transparent]' />
            </div>
          )}

          {children}
          {endAdornment && (
            <div className='ml-2 size-6 [&>*]:size-full'>{endAdornment}</div>
          )}
        </div>
      </button>
    );
  },
);
Button.displayName = 'Button';
